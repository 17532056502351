export const getBase64FromFile = (file: Blob, callback) => {
  // Convert file to base64
  const reader = new FileReader();
  reader.onload = (upload: any) => {
    callback(upload.target.result);
  };
  reader.readAsDataURL(file);
};

export const reconstituteBase64 = (contentType?: string, content?: string) => {
  if (!contentType || !content) return undefined;
  return `data:${contentType};base64,${content}`;
};

export const splitBase64 = (base64: string) => {
  return base64.replace('data:', '').replace(';base64', '').trim().split(',');
};
