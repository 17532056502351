/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * PopupOTP
 *
 */
import { memo, MouseEventHandler, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { CustomButtonCancel } from 'app/components/CustomButtonCancel';
import { CustomButtonValidate } from 'app/components/CustomButtonValidate';
import { Form } from 'antd';

import './assets/css/styles.css';
import OtpInput from 'react-otp-input';
import { LoadingIndicator } from '../LoadingIndicator';
import { ErrorMessage } from '../ErrorMessage/Loadable';
import { calculateTimeLeft, TimeLeft } from 'utils/timer';
import moment from 'moment';

interface Props extends ModalProps {
  onHide?: any;
  onSubmit?: any;
  title: string;
  subTitle: string;
  icon?: any;
  resendOtp?: MouseEventHandler;
  loading?: boolean;
  error?: string;
}

function format(number: number) {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export const PopupOTP = memo((props: Props) => {
  const { onHide, onSubmit, title, subTitle, icon: Icon, resendOtp, loading = false, error, ...rest } = props;
  const endTime = moment().add(5, 'm');

  const [otp, setOtp] = useState('');
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({ minutes: 5, seconds: 0 } as TimeLeft);

  const handleChange = otp => setOtp(otp);
  const handleSubmit = () => onSubmit(otp);

  useEffect(() => {
    var intervalId = setInterval(() => {
      const time = calculateTimeLeft(endTime);
      setTimeLeft(time);
      if (time.minutes === 0 && time.seconds === 0) clearInterval(intervalId);
    }, 500);

    return () => clearInterval(intervalId);
  }, [loading]);

  return (
    <Modal
      {...rest}
      onHide={onHide}
      centered
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      className="popup-otp"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-bottom-0 flex-column-reverse pb-0">
        <Modal.Title className="d-flex justify-content-center align-items-center flex-column align-self-center">
          {Icon && <Icon style={{ fontSize: 50, marginBottom: '10px' }} />}
          <p className="popup-otp-text-title mb-0">{title}</p>
          <p className="popup-otp-text-sub-title mb-2">{subTitle}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Form layout="vertical" className="w-75" autoComplete="on">
          <Form.Item label="Saisir le code otp récupéré" className="mb-0" required>
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span className="mx-2" />}
              shouldAutoFocus
              isInputNum
              inputStyle="input"
              className="mt-3 mb-4"
              containerStyle={{
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
              isDisabled={loading}
            />
          </Form.Item>
          <div className="message">
            Le code que vous avez reçu {timeLeft?.minutes === 0 && timeLeft?.seconds === 0 ? 'a expiré' : 'expire dans'}
            <strong>
              {' '}
              {timeLeft.minutes <= 0 && timeLeft.seconds <= 0
                ? ''
                : format(timeLeft.minutes) + ':' + format(timeLeft.seconds)}
            </strong>{' '}
            <a onClick={resendOtp} className="resend-verification-code">
              Renvoyer le code
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <LoadingIndicator loading={loading} />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <ErrorMessage error={error} />
          </div>
          <div className="container-modal-btn mt-0 mb-3">
            <CustomButtonCancel text="Annuler" className="me-3" onClick={onHide} />
            <CustomButtonValidate onClick={handleSubmit} isValid={!loading && otp.length === 6} text="Confirmer" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
