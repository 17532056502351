/**
 *
 * Asynchronously loads the component for LoginePage
 *
 */

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';
import { lazyLoad } from 'utils/loadable';

export const LoginePage = lazyLoad(
  () => import('./index'),
  module => module.LoginePage,
  {
    fallback: <PageLoadingIndicator />,
  },
);
