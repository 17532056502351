import {
  configureStore,
  getDefaultMiddleware,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  isRejected,
} from '@reduxjs/toolkit';

import { createReducer } from './reducers';
import { rootApi, rootApiFlagWithoutToken, rootApiWithoutToken } from 'app/services/api';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/error';
import { delay } from 'utils/delay';
import history from 'utils/history';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (isRejectedWithValue(action)) {
    toast.error(getErrorMessage(action?.payload));
  } else if (isRejected(action) && action?.error?.name !== 'ConditionError') {
    toast.error(getErrorMessage(action.error));
  }

  if (isRejected(action) && action?.payload?.status === 401) {
    toast.error('Votre session à expirée. Vous allez être déconnecté dans 5 secondes.');
    delay(5000).then(() => {
      localStorage.removeItem('id_token');
      history.push('/');
    });
  }

  return next(action);
};

export function configureAppStore() {
  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      rtkQueryErrorLogger,
      ...getDefaultMiddleware()
        .concat(rootApi.middleware)
        .concat(rootApiWithoutToken.middleware)
        .concat(rootApiFlagWithoutToken.middleware),
    ],
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
  });

  return store;
}
