/**
 *
 * CustomButtonCancelPaiement
 *
 */
import React, { memo } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './assets/css/styles.css';

interface Props extends ButtonProps {
  isValid?: boolean;
  className?: string;
  text: string;
}

export const CustomButtonCancelPaiement = memo((props: Props) => {
  const { className, text, ...rest } = props;
  return (
    <Button variant="outlined" type="button" className={`custom-btn-reset-paiement mr-3 ${className}`} {...rest}>
      {text}
    </Button>
  );
});
