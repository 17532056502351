/**
 *
 * PageLoadingIndicator
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator } from '../LoadingIndicator';

interface Props {
  height?: string;
}

export const PageLoadingIndicator = memo(({ height }: Props) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: height || '100vh' }}>
      <LoadingIndicator loading />
    </div>
  );
});
