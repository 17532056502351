import { PaginationRequest } from 'app/services/api/CommonApi/types';
import { useCallback, useState } from 'react';

export function useCustomHook<T>(id?: number): UseCustomHook<T> {
  const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false);
  const [showPopupEdit, setShowPopupEdit] = useState<boolean>(false);
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [showPopupDetails, setShowPopupDetails] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<T>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationRequest, setPaginationRequest] = useState<PaginationRequest>({
    size: 10,
    page: 0,
    query: '',
    id,
  });

  const togglePopupAdd = useCallback(() => {
    setShowPopupAdd(!showPopupAdd);
  }, [showPopupAdd]);

  const onShowPopupEdit = useCallback(
    (item: T) => () => {
      setShowPopupEdit(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupEdit = useCallback(() => {
    setShowPopupEdit(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupDelete = useCallback(
    (item: T) => () => {
      setShowPopupDelete(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupDelete = useCallback(() => {
    setShowPopupDelete(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupDetails = useCallback(
    (item: T) => () => {
      setShowPopupDetails(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupDetails = useCallback(() => {
    setShowPopupDetails(false);
    setSelectedItem(undefined);
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      setPaginationRequest({ ...paginationRequest, page: page - 1 });
      setCurrentPage(page);
    },
    [paginationRequest],
  );

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const query = typeof e === 'string' ? e : e.target.value;
      setPaginationRequest({
        ...paginationRequest,
        query,
      });
    },
    [paginationRequest],
  );

  return {
    showPopupAdd,
    togglePopupAdd,
    showPopupEdit,
    showPopupDetails,
    onShowPopupEdit,
    onClosePopupEdit,
    showPopupDelete,
    onShowPopupDelete,
    onClosePopupDelete,
    onShowPopupDetails,
    onClosePopupDetails,
    selectedItem,
    setSelectedItem,
    currentPage,
    onPageChange,
    paginationRequest,
    setPaginationRequest,
    onSearch,
  };
}

export interface UseCustomHook<T> {
  showPopupAdd: boolean;
  togglePopupAdd: () => void;
  showPopupEdit: boolean;
  onShowPopupEdit: (item: T) => () => void;
  onClosePopupEdit: () => void;
  showPopupDelete: boolean;
  onShowPopupDelete: (item: T) => () => void;
  onClosePopupDelete: () => void;
  showPopupDetails: boolean;
  onShowPopupDetails: (item: T) => () => void;
  onClosePopupDetails: () => void;
  selectedItem?: T;
  setSelectedItem: (item: T) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  paginationRequest: PaginationRequest;
  setPaginationRequest: (paginationRequest: PaginationRequest) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
}
