/**
 *
 * PaiementHeader
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import Logo from './assets/img/paiement_image.jpg';
import './assets/css/styles.css';

interface Props {
  image: string;
}

export const PaiementHeader = memo((props: Props) => {
  const { image } = props;
  return (
    <div className="d-flex align-items-center justify-content-center bloc-logo">
      <img src={image} alt="Logo-yewuu" />
    </div>
  );
});

const Div = styled.div``;
