import moment from 'moment';

export const calculateTimeLeft = endTime => {
  const difference = endTime.diff(moment());

  const result: TimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    result.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    result.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    result.minutes = Math.floor((difference / 1000 / 60) % 60);
    result.seconds = Math.floor((difference / 1000) % 60);
  }

  return result;
};

export interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
