/**
 *
 * CustomInputPhone
 *
 */
import React, { memo } from 'react';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import './assets/css/styles.css';
import { Form, Row, Col, Select, InputNumber, InputNumberProps } from 'antd';
import { useGetAllCountriesQuery } from 'app/services/api/CommonApiWithoutToken';
import { Country } from 'app/services/api/CommonApi/types';
import { useCustomHook } from 'utils/useCustomHook';
import styled from 'styled-components';
import { CustomInputIndicatif } from './components/CustomInputIndicatif';

interface Props extends InputNumberProps {
  nameInputIndicatif: string;
  nameInput: string;
  labelIndicatif: string;
  label: string;
  required: boolean;
  errorMessage?: string;
  placeholder?: string;
  readOnly?: boolean;
}

export const CustomInputPhone = memo((props: Props) => {
  const {
    nameInputIndicatif,
    labelIndicatif,
    nameInput,
    label,
    required,
    errorMessage,
    placeholder,
    readOnly = false,
    ...rest
  } = props;

  const { paginationRequest, onSearch } = useCustomHook<Country>();

  const { data: countries } = useGetAllCountriesQuery(paginationRequest);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={8}>
        <CustomInputIndicatif
          required={required}
          nameInput={nameInputIndicatif}
          label={labelIndicatif}
          showSuffixIcon={false}
          placeholder="Indicatif"
          className="input-phone"
          readOnly={readOnly}
          errorMessage={errorMessage}
          onSearch={onSearch}
        >
          {countries?.data?.map((country: Country) => (
            <Select.Option key={country.id} value={country.code}>
              <Image src={country.flag} alt={country.name} />
              {country.name} +({country.code})
            </Select.Option>
          ))}
        </CustomInputIndicatif>
      </Col>
      <Col span={16}>
        <Form.Item
          name={nameInput}
          label={label}
          rules={[
            {
              required: required,
              message: (
                <div style={{ marginTop: '4px' }} className="d-flex align-items-center">
                  <ErrorRoundedIcon
                    style={{
                      color: '#ff0000',
                      fontSize: '16px',
                      marginRight: '4px',
                    }}
                  />
                  {errorMessage}
                </div>
              ),
            },
          ]}
          className="form-input"
        >
          <InputNumber
            readOnly={readOnly}
            style={{ width: '100%' }}
            placeholder={placeholder}
            className="input-text"
            {...rest}
          />
        </Form.Item>
      </Col>
    </Row>
  );
});

const Image = styled.img`
  border-radius: 7px;
  width: 30px;
  margin-right: 5px;
`;
