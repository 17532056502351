/**
 *
 * Topbar
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Adress from './assets/img/adresse.svg';
import Contact from './assets/img/contact.svg';
import Phone from './assets/img/phone.svg';
import './assets/css/styles.css';

interface Props {}

export const Topbar = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <Row className="topbar align-items-center">
      <Col md={4} className="justify-content-center">
        <div className="d-flex w-100 justify-content-center align-items-center">
          <img src={Adress} alt="Adress Logo" style={{ width: '20px' }} />
          <h3 className="title">Mermoz-Sacré-Cœur, Dakar</h3>
        </div>
      </Col>
      <Col md={4} className="justify-content-center">
        <div className="d-flex w-100 justify-content-center align-items-center">
          <img src={Contact} alt="Contact Logo" style={{ width: '20px' }} />
          <h3 className="title">contact@yeewu.com</h3>
        </div>
      </Col>
      <Col md={4} className="justify-content-center">
        <div className="d-flex w-100 justify-content-center align-items-center">
          <img src={Phone} alt="Phone Logo" style={{ width: '20px' }} />
          <h3 className="title">+221 33 800 00 00</h3>
        </div>
      </Col>
    </Row>
  );
});

const Div = styled.div``;
