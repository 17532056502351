/**
 *
 * PopupError
 *
 */
import React, { memo } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { CustomButtonValidate } from '../CustomButtonValidate';
import Error from './assets/img/error.svg';

import './assets/css/styles.css';

interface Props extends ModalProps {
  message: string;
}

export const PopupError = memo((props: Props) => {
  const { message, onHide, ...rest } = props;
  return (
    <Modal {...rest} centered size="lg" aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Body className="show-grid popup-error-body p-0">
        <div className="d-flex justify-content-center align-items-center icon-ok-bloc">
          <img src={Error} alt="React Ok" className="icon-ok" />
        </div>
        <hr className="m-0" />
        <div className="d-flex justify-content-center align-items-center flex-column pt-4 second-bloc">
          <div className="congratulation-text">Erreur !</div>
          <div className="message-text w-50 text-center mb-3">{message}</div>
          <CustomButtonValidate onClick={onHide} text="Ok" className="w-25 mb-4 btn-ok" />
        </div>
      </Modal.Body>
    </Modal>
  );
});
