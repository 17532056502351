import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL_WALLET,
    prepareHeaders: headers => {
      const token = localStorage.getItem('id_token');

      // If we have a token set in localStorage, let's assume that we should be passing it.
      if (token) headers.set('Authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: builder => ({}),
});

export const rootApiWithoutToken = createApi({
  reducerPath: 'rootApiWithoutToken',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL_WALLET,
  }),
  tagTypes: [],
  endpoints: builder => ({}),
});

const baseQueryWithoutTokenRootApiFlag = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL + '/services/referential/api/v2',
});

export const rootApiFlagWithoutToken = createApi({
  reducerPath: 'rootApiFlagWithoutToken',
  baseQuery: baseQueryWithoutTokenRootApiFlag,
  tagTypes: [],
  endpoints: builder => ({}),
});

// Export hooks for usage in function components
// export const {} = rootApi;
