/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { Switch, Route, Router } from 'react-router-dom';

import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { LoginePage } from './pages/Login/LoginePage/Loadable';
import { useTranslation } from 'react-i18next';
import history from 'utils/history';
import { LayoutDashboard } from './components/LayoutDashboard/Loadable';
import { SimpleAuth } from './pages/Login/SimpleAuth/Loadable';
import { ResetPassword } from './pages/Login/ResetPassword/Loadable';
import { Paiement } from 'app/pages/Paiement';

export function App() {
  const { i18n } = useTranslation();
  return (
    <Router history={history}>
      <Helmet
        titleTemplate="%s - Yeewuna Wallet"
        defaultTitle="Yeewuna Wallet"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Yeewuna Wallet application" />
      </Helmet>

      <Switch>
        <Route exact path="/" component={LoginePage} />
        <Route exact path="/simple-auth" component={SimpleAuth} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/paiement/:key" component={Paiement} />
        <LayoutDashboard />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
