/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * PopupConfirm
 *
 */
import { memo } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { CustomButtonCancel } from 'app/components/CustomButtonCancel';
import { CustomButtonValidate } from 'app/components/CustomButtonValidate';

import './assets/css/styles.css';

interface Props extends ModalProps {
  onHide?: any;
  title: string;
  subTitle: string;
  icon?: any;
  onResultConfirm: any;
}

export const PopupConfirm = memo((props: Props) => {
  const { onHide, title, subTitle, icon: Icon, onResultConfirm, ...rest } = props;

  const handleSubmit = e => {
    onResultConfirm(e.target.value);
    onHide();
  };

  return (
    <Modal
      {...rest}
      onHide={onHide}
      centered
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      className="popup-confirm"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-bottom-0 flex-column-reverse pb-0">
        <Modal.Title className="d-flex justify-content-center align-items-center flex-column align-self-center">
          {Icon && <Icon style={{ fontSize: 50, marginBottom: '10px' }} />}
          <p className="popup-confirm-text-title mb-3">{title}</p>
          <p className="popup-confirm-text-sub-title mb-0">{subTitle}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <div className="container-modal-btn mt-0 mb-3" style={{ width: '400px' }}>
          <CustomButtonCancel text="Non" className="me-3" value="no" onClick={handleSubmit} />
          <CustomButtonValidate onClick={handleSubmit} className="w-100" value="yes" text="Oui" />
        </div>
      </Modal.Body>
    </Modal>
  );
});
