/**
 *
 * Paiement
 *
 */
import React, { memo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Topbar } from '../Login/Components/Topbar';
import Pub from './assets/img/groupe-785.jpg';
import { PaiementHeader } from './components/ImageHeader';
import { Form } from 'antd';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { CustomInputPhone } from 'app/components/CustomInputPhone';
import { CustomButtonValidatePaiement } from './components/CustomButtonValidatePaiement';
import { CustomButtonCancelPaiement } from './components/CustomButtonCancelPaiement';
import {
  useCancelPaiementMutation,
  useFinishProcessMutation,
  useGetPaiementQuery,
  useRedirectAfterCancelledMutation,
  useRedirectAfterClosedMutation,
  useRedirectAfterSucceededMutation,
  useValidatePaiementMutation,
} from 'app/services/api/PaiementApi';
import { OTP } from 'app/services/api/PaiementApi/types';
import { PopupOTP } from 'app/components/PopupOTP';
import { getErrorMessage } from 'utils/error';
import { PopupSuccess } from 'app/components/PopupSuccess';
import { PopupError } from 'app/components/PopupError';
import { formatAmount } from 'utils/formatAmount';
import { PopupConfirm } from 'app/components/PopupConfirm';
import './assets/css/styles.css';
import { PopupInfo } from 'app/components/PopupInfo';
import history from 'utils/history';

interface Props {}

interface Params {
  key: string;
}

export const Paiement = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [isVisibleOtpValidate, setIsVisibleOtpValidate] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isCancelConfirm, setIsCancelConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [otpState, setOtpState] = useState<OTP>();
  const { key } = useParams<Params>();

  const [validatePaiement, validatePaiementResult] = useValidatePaiementMutation();
  const [cancelPaiement] = useCancelPaiementMutation();
  const [finishProcess, finishProcessResult] = useFinishProcessMutation();
  const { data: paiement } = useGetPaiementQuery(key);

  const nextURL = window.location.origin + '/paiement';
  const nextTitle = 'Mon paiement';

  window.history.replaceState(null, nextTitle, nextURL);

  //Close Tab Browser
  /* useEffect(() => {
    window.onbeforeunload = () => 'OK';
    return () => {
      window.onbeforeunload = null;
    };
  }, []); */

  const onShowOTP = () => setIsVisibleOtpValidate(true);

  const onHideOTP = () => {
    setIsVisibleOtpValidate(false);
    setError('');
  };

  const onShowSuccessModal = () => setIsSuccess(true);

  const onHideSuccessModal = () => {
    setIsSuccess(false);
    window.location.href = `https://backend-yeewuna-keycloak.finetech.solutions/services/wallet/api/wallet-web/redirect-marchant-web/sucess/${key}`;
  };

  const onShowErrorModal = () => setIsError(true);

  const onHideErrorModal = () => setIsError(false);

  const onShowCancelModal = () => setIsCancel(true);

  const onHideCancelModal = () => {
    setIsCancel(false);
  };

  const onShowCancelConfirmModal = () => setIsCancelConfirm(true);

  const onHideCancelConfirmModal = () => setIsCancelConfirm(false);

  const onResendOtp = () => {
    validatePaiement(otpState!).unwrap();
  };

  const onResultConfirmCancel = result => {
    if (result === 'yes') onCancelPaiement();
  };
  const onFinish = values => {
    const dto: OTP = { key, phoneNumber: '+' + values.indicatif + values.phoneNumber };
    setOtpState(dto);
    validatePaiement(dto)
      .unwrap()
      .then(result => {
        onShowOTP();
      });
  };

  const onCancelPaiement = () => {
    window.location.href = `https://backend-yeewuna-keycloak.finetech.solutions/services/wallet/api/wallet-web/redirect-marchant-web/cancel/${key}`;
    /*  cancelPaiement(key)
      .unwrap()
      .then(result => {
        onShowCancelModal();
      })
      .catch(err => {
        setError(getErrorMessage(err));
        onShowErrorModal();
      }); */
  };

  const onsubmitOtp = (otp: number) => {
    console.log(otp);
    const dto: OTP = { ...otpState, otp };
    finishProcess(dto)
      .unwrap()
      .then(() => {
        onHideOTP();
        onShowSuccessModal();
      })
      .catch(err => {
        setError(getErrorMessage(err));
      });
  };
  return (
    <Container fluid>
      <Topbar />
      <Row className="login-bottom-width" />
      <PaiementHeader image={paiement?.logo!} />
      <Row style={{ margin: '20px 5% 0 5%' }}>
        <Col md={6}>
          <p className="txt-title">Paiement web</p>
          <Row style={{ backgroundColor: '#f2f2f2', color: '#414141', borderRadius: '10px', padding: '12px 0' }}>
            <Col md={6} className="checkout-title">
              Partenaire
            </Col>
            <Col md={6} className="checkout-value">
              {paiement?.partnerName}
            </Col>
          </Row>
          <Row style={{ padding: '12px 0' }}>
            <Col md={6} className="checkout-title">
              Réference commande
            </Col>
            <Col md={6} className="checkout-value">
              {paiement?.referenceCommande}
            </Col>
          </Row>
          <Row style={{ backgroundColor: '#f2f2f2', color: '#414141', borderRadius: '10px', padding: '12px 0' }}>
            <Col md={6} className="checkout-title">
              Commande
            </Col>
            <Col md={6} className="checkout-value">
              {paiement?.description}
            </Col>
          </Row>

          <div className="checkout-separator" />
          <Row>
            <Col md={6} className="checkout-title">
              Total de votre commande
            </Col>
            <Col md={6} className="checkout-value">
              {formatAmount(paiement?.amount!, 'FCFA')}
            </Col>
          </Row>
          <p className="txt-title mt-4">Paiement sécurisé avec Yeewu</p>
          <Form layout="vertical" onFinish={onFinish} autoComplete="on">
            <CustomInputPhone
              nameInput="phoneNumber"
              label="Téléphone"
              required
              errorMessage="Champ obligatoire"
              placeholder="Entrer votre numero de téléphone"
              nameInputIndicatif="indicatif"
              labelIndicatif="Indicatif"
            />

            <div className="d-flex justify-content-center mb-4 align-items-center ">
              <LoadingIndicator loading={validatePaiementResult.isLoading} />
            </div>
            {/* <div className="d-flex justify-content-center mb-4 align-items-center ">
              <ErrorMessage error={error} />
            </div> */}
            <div className="d-flex w-100">
              <CustomButtonCancelPaiement
                onClick={onShowCancelConfirmModal}
                style={{ marginRight: '10px' }}
                text="Annuler"
              />
              <CustomButtonValidatePaiement text="Envoyer" />
            </div>
          </Form>
        </Col>
        <Col md={6}>
          <div className="d-flex align-items-center">
            <img src={Pub} alt="PubImage" className="w-100" />
          </div>
        </Col>
      </Row>

      {isVisibleOtpValidate && (
        <PopupOTP
          title="Validation du paiement"
          subTitle=""
          show={isVisibleOtpValidate}
          onHide={onHideOTP}
          onSubmit={onsubmitOtp}
          resendOtp={onResendOtp}
          loading={finishProcessResult.isLoading || validatePaiementResult.isLoading}
          error={error}
        />
      )}

      {isCancelConfirm && (
        <PopupConfirm
          title="Annulation du paiement"
          subTitle="Voulez-vous vraiment annuler votre paiement ?"
          show={isCancelConfirm}
          onHide={onHideCancelConfirmModal}
          onResultConfirm={onResultConfirmCancel}
        />
      )}

      {isSuccess && (
        <PopupSuccess
          onHide={onHideSuccessModal}
          show={isSuccess}
          message="Votre paiement a ete valider avec success"
        />
      )}

      {isCancel && (
        <PopupInfo onHide={onHideCancelModal} show={isCancel} message="Votre paiement a ete annuler avec success" />
      )}

      {isError && <PopupError onHide={onHideErrorModal} show={isError} message={error!} />}
    </Container>
  );
});
