/**
 *
 * CustomInputIndicatif
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { Form, Input, Select } from 'antd';
import ArrowUpDown from './assets/images/groupe-1283.svg';
import './assets/css/styles.css';

const { Option } = Select;

interface Props {
  nameInput: string;
  className?: string;
  label: string;
  required: boolean;
  errorMessage?: string;
  placeholder?: string;
  children: any;
  showSuffixIcon?: boolean;
  readOnly?: boolean;
  filterOption?: any;
  onSearch?: (value: string) => void;
}

export const CustomInputIndicatif = memo((props: Props) => {
  const {
    nameInput,
    label,
    required,
    errorMessage,
    children,
    placeholder,
    showSuffixIcon = true,
    readOnly = false,
    filterOption,
    className,
    onSearch,
  } = props;

  const filter = (input: string, option: any) => option.children[1].toLowerCase().includes(input.toLowerCase());

  const classes = [className, 'input-select-indicatif'].join(' ');

  return (
    <Form.Item
      name={nameInput}
      label={label}
      rules={[
        {
          required: required,
          message: (
            <div style={{ marginTop: '4px' }} className="d-flex align-items-center">
              <ErrorRoundedIcon
                style={{
                  color: '#ff0000',
                  fontSize: '16px',
                  marginRight: '4px',
                }}
              />
              {errorMessage}
            </div>
          ),
        },
      ]}
    >
      <Select
        aria-readonly={readOnly}
        className={classes}
        placeholder={placeholder}
        filterOption={filterOption || filter}
        showSearch
        onSearch={onSearch}
        suffixIcon={showSuffixIcon ? <img src={ArrowUpDown} className="icon-select" alt="IconSelect" /> : <div />}
      >
        {children}
      </Select>
    </Form.Item>
  );
});

const Div = styled.div``;
