/**
 *
 * CustomButtonValidatePaiement
 *
 */
import React, { memo } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './assets/css/styles.css';

interface Props extends ButtonProps {
  isValid?: boolean;
  className?: string;
  text: string;
  icon?: any;
}

export const CustomButtonValidatePaiement = memo((props: Props) => {
  const { isValid = true, className, text, icon, ...rest } = props;
  return (
    <Button
      type="submit"
      className={`btn-valider ${className} ${isValid && 'valid-btn'}`}
      disabled={!isValid}
      {...rest}
    >
      {icon} {text}
    </Button>
  );
});
